
import React, { useState } from 'react';
import tw from "tailwind-styled-components"
import { Formik, Form, Field } from 'formik';
import FormRatings,{Stars} from 'form-ratings'
import * as Yup from 'yup';

function OnSubmit(values){
  console.log(values)
}
const SuverySchema = Yup.object().shape({
  structureRating: Yup.object(),
  productRating:  Yup.object(),
  fastEnough :Yup.boolean().required().oneOf(["No" , "Si"]),
  recomendation :Yup.boolean().required().oneOf(["No" , "Si", "Tal vez"])
})


class App extends React.Component{
  render(){
    return (
        <div className={ " flex bg-gradient-to-t from-gray-700 via-gray-900 to-black text-white h-screen w-full justify-center text-center"}>
          <Container>
            <Formik initialValues={
              {
                atentionRating:1,
                structureRating:1,
                productRating:1,
                commentary:"asd",
                fastEnough:true,
                recomendation:true
              }
            } onSubmit={OnSubmit} >
              <Form className={"items-center content-center justify-items-center "}>
                <InputField>
                <h3>
                  Como calificaria nuestra atencion:
                </h3>
                <Field name="atentionRating" as={FormRatings} />
                </InputField>
                <InputField>
                  <h3>
                    Como calificaria nuestros productos:
                  </h3>
                  <Field name="productRating" as={FormRatings} />
                </InputField>
                <InputField>
                <div id="my-radio-group">La atención fue lo suficientemente rápida? </div>
                <div role="group" aria-labelledby="my-radio-group">
                  <label className={"m-5"}>
                    <Field type="radio" name="fastEnough" value="Si"/>
                    Si
                  </label>
                  <label className={"m-5"}>
                    <Field type="radio" name="fastEnough" value="No" />
                    No
                  </label>
                </div>
                </InputField>
              <InputField>
                <div id="my-radio-group">Recomendarías este lugar a colegas y amigos?   </div>
                <div role="group" aria-labelledby="my-radio-group">
                  <label className={"m-5"}>
                    <Field type="radio" name="recomendation" value="Si"/>
                    Si
                  </label>
                  <label className={"m-5"}>
                    <Field type="radio" name="recomendation" value="No" />
                    No
                  </label>
                  <label className={"m-5"}>
                    <Field type="radio" name="recomendation" value="Tal vez" />
                    Tal vez
                  </label>
                </div>
              </InputField>
                <InputField>
                <h3 className={"text-white"}>Comentario: </h3>
                <TextField>
                  <Field type="text" name="comentary" placeholder="Escriba aqui su comentario" />
                </TextField>
                </InputField>
                <button type="submit"
                        className=" w-1/2 inline-block px-6 py-2 border-2 border-white text-white font-medium text-xs leading-normal uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                  Enviar
                </button>
              </Form>
            </Formik>
          </Container>
        </div>
    )
  }
}
const Container = tw.div`
    border
    rounded-lg
    border-white
    p-t
    items-center
    h-screen
    my-1
    w-full
    


`
const TextField = tw.div`
  text-black
  text-center
  m-1
  rounded-lg
md:box-content
  w-full
  px-200
`


const InputField = tw.div`
  border
  border-white
  m-1
  bg-transparent
  
`
export default App;
